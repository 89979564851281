@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
    margin: 0;
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000
}

body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

body.isInApp {
  overflow: -moz-scrollbars-none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  scrollbar-width: none; /* For Firefox */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
body.isInApp::-webkit-scrollbar {
  display: none;
}

/* Apply only on touch screens */
@media (pointer: coarse) {
  body.isInApp {
    /* Hide scrollbars on touch devices */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* Ensure smooth scrolling for iOS */
  }
}


/* used for scrollable time picker */

.scroll-snap-y {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

.scroll-snap-center {
  scroll-snap-align: center;
}

.time-picker {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1C1C1E;
}

.picker-container {
  display: flex;
  justify-content: space-between; 
  height: 100%;
  position: relative;
  z-index: 2;
  width: 160px; 
  gap: 12px; 
  flex-direction: row;
  align-items: center;
}

.scroll-container {
  flex: 1;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: 100%;
  position: relative;
  z-index: 2;
  align-items: center;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 0.2) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.time-item {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  font-size: 17px; 
  color: #FFFFFF; 
  font-weight: 300;
  transition: all 0.2s ease;
  letter-spacing: 0.2px;
}

.time-item.selected {
  color: #FFFFFF; 
  font-weight: 400;
}

.scroll-padding {
  height: 35px;
}

.selection-indicator {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  height: 35px;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
  border-radius: 4px;
  background-color: #3A3A3C; 
  margin: 0 8px;
}

/* Hide scrollbar */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-container {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}


/* custom transitions for friend selector */
@keyframes slideIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(20px);
  }
}


/* custom styles for cropper */
.reactEasyCrop_CropArea {
  border-radius: 12px;
}

/* .reactEasyCrop_CropAreaGrid {
  border-radius: 12px;
  min-width: 90%;
  min-height: 90%;
  width: 90%;
  height: 90%;
} */


/* gradient blur make specifically for playlist page "playlists connected" */

.gradient-blur {
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  z-index: 5;
  height: 100%;
  pointer-events: none;
}
.gradient-blur > div,
.gradient-blur::before,
.gradient-blur::after {
  position: absolute;
  inset: 0;
}
.gradient-blur::before {
  content: "";
  z-index: 1;
  backdrop-filter: blur(0.5px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 12.5%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 37.5%
  );
}
.gradient-blur > div:nth-of-type(1) {
  z-index: 2;
  backdrop-filter: blur(1px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 12.5%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 1) 37.5%,
    rgba(0, 0, 0, 0) 50%
  );
}
.gradient-blur > div:nth-of-type(2) {
  z-index: 3;
  backdrop-filter: blur(2px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 12.5%,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 1) 37.5%,
    rgba(0, 0, 0, 0) 50%
  );
}
.gradient-blur > div:nth-of-type(3) {
  z-index: 4;
  backdrop-filter: blur(4px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 1) 37.5%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 62.5%
  );
}
.gradient-blur > div:nth-of-type(4) {
  z-index: 5;
  backdrop-filter: blur(8px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 37.5%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 1) 62.5%,
    rgba(0, 0, 0, 0) 75%
  );
}
.gradient-blur > div:nth-of-type(5) {
  z-index: 6;
  backdrop-filter: blur(16px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 62.5%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 0) 87.5%
  );
}
.gradient-blur > div:nth-of-type(6) {
  z-index: 7;
  backdrop-filter: blur(32px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 62.5%,
    rgba(0, 0, 0, 1) 75%,
    rgba(0, 0, 0, 1) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
}
.gradient-blur > div:nth-of-type(7) {
  z-index: 8;
  backdrop-filter: blur(64px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 1) 87.5%,
    rgba(0, 0, 0, 1) 100%
  );
}
.gradient-blur::after {
  content: "";
  z-index: 8;
  backdrop-filter: blur(64px);
  mask: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 87.5%,
    rgba(0, 0, 0, 1) 100%
  );
}

/* Transition for sliding content into view */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}